import React from 'react';
import styles from './Me.module.css';
import meshi from "../../images/פזית שטה.png"

const AboutMe = () => {
  return <>
      <div className={styles.header} id="מי אני">איך אני יודעת את זה?</div>
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={meshi} alt="פזית שטה" className={styles.image} />
      </div>
      <div className={styles.content}>
        <h1 className={styles.title}>פזית שטה</h1>
        <h2 className={styles.subtitle}>
  הייתי בדיוק במקום שלך!
  </h2>
        <p className={styles.description}>
        שקלתי 25 קילו יותר והייתי סובלת מהפרעות אכילה למעלה מ10 שנים. הייתי חיה בתסכול עצום מול עצמי ומול הגוף שלי, כי ניסתי כ״כ הרבה שיטות שאמנם תמיד בהתחלה כן הייתי יורדת אך בסופו של דבר אחרי התהליך תמיד הייתי עולה יותר במשקל, וגרוע מכך אותן שיטות הותירו אותי מצולקת נפשית.
התסכול האמיתי היה שחשבתי שתמיד הבאג היה אצלי ובגוף שלי, שלימים הבנתי שלא התזונה ולא דרך החיים שאותם תהליכים הכתיבו - לא התאימו לי.. 
ומאחר והייתי חיה בסבל הזה כ״כ הרבה שנים, אני יודעת על בשרי מה כל אישה חווה בסקאלה של העליה והירידה במשקל.

        </p>

        <p className={styles.description}>
        בשנה האחרונה ליוותי כ300 נשים לאיזון משקל עודף כמלווה ומאמנת תזונה בתוכנית מוכרת של רופא בתחום הרפואה הטבעית בארץ.</p>
        <p className={styles.description}>
בהכשרתי למדתי יעוץ תזונה ובריאות טבעית והוסמכתי לסייע לאנשים לאזן משקל עודף ומחלות וגם עברתי התמחות בתחום התזונה והריפוי הטבעי. למדתי master NLP, ייעוץ בפסיכותרפיה הוליסטית יהודית, ריפוי מאכילה רגשית,ריפוי מכאבים כרוניים של מחלות בשיטת tms. 
כתחביב למדתי הוראה ליוגה ואיזון הנפש ברוח היהדות. ובנוסף - עוד המון שיטות, תהליכים ומידע בהקשר לגוף ולנפש שלנו.</p>
        <p className={styles.description}>
וכך יצרתי תהליך ריפוי עצמי ותזונתי הן מהניסיון האישי והן מהניסיון המקצועי שלי נוצר תהליך שלם שיהפוך אותך למאושרת,מאוזנת ממשקל עודף,ויציבה גם אחריו! 
בתהליך איתי אני משלבת טיפול גוף נפש ואימון מנטלי נשי לפי האישיות הפרסונלית שלך, ולפי המצב הפיזיולוגי הנוכחי שלך. 
וליווי צמוד אישי שלי 1 על 1 ופגישה שבועית בזום</p>
      </div>
    
    </div>

    </>
};

export default AboutMe;